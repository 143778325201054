import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import HeroData from './HeroData';
import EnemyData from './EnemyData';
import GoldData from './GoldData';

function Main() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    // If the token does not exist, navigate to the login page
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1', mt: 5 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        TabIndicatorProps={{
          style: { backgroundColor: '#000' } // Customize tab indicator color (optional)
        }}
      >
        <Tab
          label="Hero Data"
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Roboto, Arial, sans-serif',
            fontSize: '1rem'
          }}
        />
        <Tab
          label="Enemy Data"
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Roboto, Arial, sans-serif',
            fontSize: '1rem'
          }}
        />
        <Tab
          label="Gold Data"
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Roboto, Arial, sans-serif',
            fontSize: '1rem'
          }}
        />
      </Tabs>

      {/* Display the corresponding content based on the active tab */}
      <Box sx={{ mt: 3 }}>
        {value === 0 && <HeroData />}
        {value === 1 && <EnemyData />}
        {value === 2 && <GoldData />}
      </Box>
    </Box>
  );
}

export default Main;
