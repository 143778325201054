import React, { useEffect, useState, useRef } from 'react';
import { useTable, useFilters } from 'react-table';
import axios from 'axios';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useSensor, useSensors, MouseSensor, TouchSensor, KeyboardSensor } from '@dnd-kit/core';
import Modal from 'react-modal';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';

// Sortable row component using dnd-kit
const SortableRow = ({ row, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: row.original.unique_id, // Each row should have a unique id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </tr>
  );
};

// Editable Cell Component for text or number inputs
const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData, type = 'text', disabled = false }) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => setValue(e.target.value);

  const onBlur = () => {
    let newValue = value;

    if (type === 'number') {
      newValue = parseFloat(value);
      if (!isNaN(newValue)) {
        newValue = parseFloat(newValue.toFixed(2)); // Round to 2 decimal points
      } else {
        newValue = 0.00; // Fallback to 0 if value is not valid
      }
    }

    updateMyData(index, id, newValue || '');
  };

  return (
    <input
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      className="form-control"
      disabled={disabled} // Disable input when necessary
    />
  );
};

// Editable Dropdown Component for select inputs
const EditableDropdown = ({ value: initialValue, row: { index }, column: { id }, updateMyData, options = [], disabled = false }) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    const selectedValue = e.target.value;
    setValue(selectedValue);
    updateMyData(index, id, selectedValue || '');
  };

  return (
    <select
      value={value || ''}
      onChange={onChange}
      className="form-control"
      disabled={disabled} // Disable dropdown when necessary
    >
      <option value="">Select...</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

// Popup Modal Component with Editable Fields
const PopupModal = ({ isOpen, onClose, content, title, updateMyData, rowIndex }) => {
  const [localContent, setLocalContent] = useState(content);

  useEffect(() => {
    setLocalContent(content);
  }, [content]);

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // Transparent dark background
      zIndex: 1000, // Ensure the modal is on top
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)', // Center the modal
      width: '500px', // Set a fixed width
      padding: '20px',
      borderRadius: '10px', // Rounded corners
      backgroundColor: '#fff', // White background for modal
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)', // Add some shadow
      animation: 'fadeIn 0.3s ease-in-out', // Fade in animation
      position: 'relative', // Ensure absolute children like buttons work correctly
      overflow: 'visible', // Ensure overflow is handled for form elements
    },
  };

  const handleChange = (field, value) => {
    setLocalContent((prev) =>
      prev.map((item) => (item.field === field ? { ...item, value } : item))
    );
  };

  const handleSave = () => {
    const keyIdItem = localContent.find((item) => item.field.includes('keyId'));
    const typeItem = localContent.find(
      (item) =>
        item.field.includes('attackType') ||
        item.field.includes('effectType') ||
        item.field.includes('skillType')
    );

    if (!keyIdItem || !keyIdItem.value || keyIdItem.value === 'Select...') {
      localContent.forEach((item) => {
        updateMyData(rowIndex, item.field, typeof item.value === 'number' ? 0 : ""); // Set numeric fields to 0 and string fields to ""
      });
      onClose(); // Close modal after updating
      return;
    }

    if (!typeItem || !typeItem.value || typeItem.value === 'Select...') {
      alert('keyId 또는 type이 선택되지 않았습니다.\n선택하거나 close 해주세요.');
      return;
    }

    // Save the values if both keyId and type are filled
    localContent.forEach((item) => {
      updateMyData(rowIndex, item.field, item.value);
    });
    onClose();
  };

  const isKeyIdFilled = localContent.some(
    (item) => item.field.includes('keyId') && item.value.length > 0
  );
  const isTypeFilled = localContent.some(
    (item) =>
      (item.field.includes('attackType') ||
        item.field.includes('effectType') ||
        item.field.includes('skillType')) &&
      item.value &&
      item.value !== 'Select...'
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      contentLabel={title}
      style={customStyles}
      className="custom-popup-modal"
    >
      <h2>{title}</h2>
      <div>
        {localContent.map((item, i) => (
          <div key={i} className="modal-item" style={{ display: 'flex' }}>
            <strong>{item.label}:</strong>
            {item.type === 'select' ? (
              <div style={{ marginLeft: '14px' }}>
                <EditableDropdown
                  value={item.value}
                  row={{ index: rowIndex }}
                  column={{ id: item.field }}
                  updateMyData={(index, id, value) => handleChange(id, value)}
                  options={item.options}
                  disabled={item.field.includes('keyId') ? false : !isKeyIdFilled} // Disable only if keyId isn't filled
                />
              </div>
            ) : (
              <div style={{ marginLeft: '16px' }}>
                <EditableCell
                  value={item.value}
                  row={{ index: rowIndex }}
                  column={{ id: item.field }}
                  updateMyData={(index, id, value) => handleChange(id, value)}
                  type={item.inputType || 'text'}
                  disabled={!isKeyIdFilled || !isTypeFilled} // Disable based on conditions
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="modal-actions">
        <button
          onClick={handleSave}
          className="btn btn-primary"
        >
          Save
        </button>
        <button onClick={onClose} className="btn btn-secondary">
          Close
        </button>
      </div>
    </Modal>
  );
};

// Main Hero Data Component
function HeroData() {
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login'); // Redirect to login page if token is missing
      return;
    }

    axios
      .get('/api/hero-data', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.heroes);
      })
      .catch((error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          // Token has expired or is invalid, redirect to login page
          localStorage.removeItem('token'); // Clear the invalid token
          navigate('/login'); // Redirect to login page
        } else {
          console.error('Error fetching hero data:', error);
        }
      });
  }, [navigate]);

  // Save hero data
  const saveData = () => {
    const token = localStorage.getItem('token');
    axios
      .post(
        '/api/save-hero-data',
        { heroes: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => alert('Hero data saved successfully!'))
      .catch((error) => console.error('Error saving hero data:', error));
  };

  // Update data in table
  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const keys = columnId.split('.');
          let updatedRow = { ...row };

          if (keys.length > 1) {
            let objRef = updatedRow;
            for (let i = 0; i < keys.length - 1; i++) {
              objRef = objRef[keys[i]];
            }
            objRef[keys[keys.length - 1]] = value || (typeof value === 'number' ? 0 : '');
          } else {
            updatedRow[columnId] = value || (typeof value === 'number' ? 0 : '');
          }
          return updatedRow;
        }
        return row;
      })
    );
  };

  // Drag and Drop Logic
  const handleDragEnd = (event) => {
    const { active, over } = event;

    // Prevent error if 'over' is null
    if (!over) return;

    if (active.id !== over.id) {
      setData((oldData) => {
        const oldIndex = oldData.findIndex((row) => row.unique_id === active.id);
        const newIndex = oldData.findIndex((row) => row.unique_id === over.id);
        return arrayMove(oldData, oldIndex, newIndex);
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,  // Drag activates after moving 8 pixels
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,    // Drag activates after holding for 200ms
        tolerance: 6,  // Small tolerance for finger movements
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Open modal with appropriate content and pass rowIndex for updates
  const openModal = (title, content, rowIndex) => {
    // Update both content and row index at once using a function inside setState
    setModalTitle(title);
    setModalContent(content);
    setCurrentRowIndex(rowIndex);

    // Delay the modal opening to ensure all states are updated first
    setTimeout(() => {
      setModalIsOpen(true);
    }, 0);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const scrollToBottom = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const addRow = () => {
    const newRow = {
      unique_id: `${data.length}-${Date.now()}`, // Generate unique ID
      keyId: '', // Set default values for new row
      heroType: '',
      heroTier: '',
      heroName: '',
      attackSpeed: 0.00,
      attackRange: 0.00,
      moveSpeed: 0.00,
      baseAttack: {
        keyId: '',
        attackType: '',
        damage: 0.00,
        sideEffectAttack: {
          keyId: '',
          effectType: '',
          effectPower: 0.00,
          effectRange: 0.00,
          triggerOnAttackCount: 0,
          probability: 0.00,
          effectDuration: 0.00
        }
      },
      skillAttack: {
        keyId: '',
        skillType: '',
        skillDamage: 0.00,
        cooldown: 0.00
      },
      spawnProbability: 0.00,
      price: 0.00,
    };
    setData((oldData) => [...oldData, newRow]);
    scrollToBottom(); // Scroll to the last added row
  };

  // Remove a row from the data
  const removeRow = (unique_id) => {
    setData((oldData) => oldData.filter((row) => row.unique_id !== unique_id));
  };

  // Function to get distinct dropdown values
  const getDistinctValues = (data, key) => {
    const distinct = new Set();
    data.forEach((item) => {
      const keys = key.split('.');
      let value = item;
      keys.forEach((k) => (value = value?.[k]));
      if (value) distinct.add(value);
    });
    return Array.from(distinct);
  };

  const columns = React.useMemo(() => {
    const distinctKeyIds = getDistinctValues(data, 'keyId');
    const distinctHeroTypes = getDistinctValues(data, 'heroType');
    const distinctHeroTiers = getDistinctValues(data, 'heroTier');
    const distinctBaseAttackKeyIds = getDistinctValues(data, 'baseAttack.keyId');
    const distinctBaseAttackTypes = getDistinctValues(data, 'baseAttack.attackType');
    const distinctSideEffectKeyIds = getDistinctValues(data, 'baseAttack.sideEffectAttack.keyId');
    const distinctSideEffectTypes = getDistinctValues(data, 'baseAttack.sideEffectAttack.effectType');
    const distinctSkillAttackKeyIds = getDistinctValues(data, 'skillAttack.keyId');
    const distinctSkillAttackTypes = getDistinctValues(data, 'skillAttack.skillType');

    return [
      {
        Header: '', // Drag handle column
        id: 'drag',
        disableSortBy: true,
        width: 50, // Set a fixed width for the drag handle
        Cell: () => <span className="drag-handle">&#9776;</span>, // Icon for drag handle
      },
      {
        Header: 'Key ID',  // Separate column for Key ID
        accessor: 'keyId',
        Cell: (props) => <EditableDropdown {...props} options={distinctKeyIds} />
      },
      { Header: 'Hero Type', accessor: 'heroType', Cell: (props) => <EditableDropdown {...props} options={distinctHeroTypes} /> },
      { Header: 'Hero Tier', accessor: 'heroTier', Cell: (props) => <EditableDropdown {...props} options={distinctHeroTiers} /> },
      { Header: 'Hero Name', accessor: 'heroName', Cell: EditableCell },
      { Header: 'Attack Speed', accessor: 'attackSpeed', Cell: (props) => <EditableCell {...props} type="number" /> },
      { Header: 'Attack Range', accessor: 'attackRange', Cell: (props) => <EditableCell {...props} type="number" /> },
      { Header: 'Move Speed', accessor: 'moveSpeed', Cell: (props) => <EditableCell {...props} type="number" /> },

      // Popup Buttons for Base Attack, Side Effect Attack, and Skill Attack
      {
        Header: 'Base Attack',
        accessor: 'baseAttack',
        Cell: ({ row }) => (
          <button
            className="btn btn-primary"
            onClick={() =>
              openModal('Base Attack', [
                { label: 'Key ID', value: row.original.baseAttack?.keyId, field: 'baseAttack.keyId', type: 'select', options: distinctBaseAttackKeyIds },
                { label: 'Attack Type', value: row.original.baseAttack?.attackType, field: 'baseAttack.attackType', type: 'select', options: distinctBaseAttackTypes },
                { label: 'Damage', value: row.original.baseAttack?.damage, field: 'baseAttack.damage', inputType: 'number' }
              ], row.index)
            }
          >
            Base Attack
          </button>
        ),
      },
      {
        Header: 'Side Effect Attack',
        accessor: 'sideEffectAttack',
        Cell: ({ row }) => (
          <button
            className="btn btn-primary"
            onClick={() => openModal('Side Effect Attack', [
              { label: 'Key ID', value: row.original.baseAttack?.sideEffectAttack?.keyId, field: 'baseAttack.sideEffectAttack.keyId', type: 'select', options: distinctSideEffectKeyIds },
              { label: 'Effect Type', value: row.original.baseAttack?.sideEffectAttack?.effectType, field: 'baseAttack.sideEffectAttack.effectType', type: 'select', options: distinctSideEffectTypes },
              { label: 'Effect Power', value: row.original.baseAttack?.sideEffectAttack?.effectPower, field: 'baseAttack.sideEffectAttack.effectPower', inputType: 'number' },
              { label: 'Effect Range', value: row.original.baseAttack?.sideEffectAttack?.effectRange, field: 'baseAttack.sideEffectAttack.effectRange', inputType: 'number' },
              { label: 'Trigger on Attack Count', value: row.original.baseAttack?.sideEffectAttack?.triggerOnAttackCount, field: 'baseAttack.sideEffectAttack.triggerOnAttackCount', inputType: 'number' },
              { label: 'Probability', value: row.original.baseAttack?.sideEffectAttack?.probability, field: 'baseAttack.sideEffectAttack.probability', inputType: 'number' },
              { label: 'Duration', value: row.original.baseAttack?.sideEffectAttack?.effectDuration, field: 'baseAttack.sideEffectAttack.effectDuration', inputType: 'number' }
            ], row.index)}
          >
            Side Effect Attack
          </button>
        ),
      },
      {
        Header: 'Skill Attack',
        accessor: 'skillAttack',
        Cell: ({ row }) => (
          <button
            className="btn btn-primary"
            onClick={() => openModal('Skill Attack', [
              { label: 'Key ID', value: row.original.skillAttack?.keyId, field: 'skillAttack.keyId', type: 'select', options: distinctSkillAttackKeyIds },
              { label: 'Skill Type', value: row.original.skillAttack?.skillType, field: 'skillAttack.skillType', type: 'select', options: distinctSkillAttackTypes },
              { label: 'Skill Damage', value: row.original.skillAttack?.skillDamage, field: 'skillAttack.skillDamage', inputType: 'number' },
              { label: 'Cooldown', value: row.original.skillAttack?.cooldown, field: 'skillAttack.cooldown', inputType: 'number' }
            ], row.index)}
          >
            Skill Attack
          </button>
        ),
      },

      // Other general fields (e.g., spawnProbability, price)
      { Header: 'Spawn Probability', accessor: 'spawnProbability', Cell: (props) => <EditableCell {...props} type="number" /> },
      { Header: 'Price', accessor: 'price', Cell: (props) => <EditableCell {...props} type="number" /> },
      {
        Header: 'Delete',
        id: 'actions',
        Cell: ({ row }) => (
          <span
            className="remove-icon"
            onClick={() => removeRow(row.original.unique_id)}
            style={{ cursor: 'pointer', color: '#dc3545' }} // Red color for delete action
          >
            <i className="fas fa-times"></i> {/* Font Awesome X icon */}
          </span>
        ),
      }
    ];
  }, [data]);


  // Table instance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data, updateMyData }, useFilters);

  return (
    <div className="container">
      <h1 className="text-center">Hero Data</h1>
      <button className="save-button" onClick={saveData}>
        Save Hero Data
      </button>
      <button className="save-button" onClick={addRow} style={{ marginLeft: '20px' }}>
        Add Row
      </button> {/* Add Row Button */}

      <div className="table-container">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext items={rows.map((row) => row.original.unique_id)}>
            <table {...getTableProps()} className="custom-table" ref={tableRef}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <SortableRow key={row.original.unique_id} row={row}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </SortableRow>
                  );
                })}
              </tbody>
            </table>
          </SortableContext>
        </DndContext>
      </div>

      <PopupModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        content={modalContent}
        title={modalTitle}
        updateMyData={updateMyData}
        rowIndex={currentRowIndex}
      />
    </div>
  );
}

export default HeroData;
