import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';

function PrivateRoute({ children }) {
  const [isValidSession, setIsValidSession] = useState(true); // Track session validity
  const token = localStorage.getItem('token');
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    if (token) {
      // Verify token validity by making a request to the backend
      axios.get('/main', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(() => {
        setIsValidSession(true); // Token is valid
      }).catch((error) => {
        localStorage.removeItem('token'); // Remove invalid token
        alert(error.response?.data || 'Session is timeout. Please log in again.');
        setIsValidSession(false); // Set session as invalid
        navigate('/login'); // Navigate to login page
      });
    } else {
      setIsValidSession(false); // No token, session is invalid
      navigate('/login'); // Navigate to login page if no token
    }
  }, [token, navigate]);

  if (!isValidSession) {
    return null; // No need to show anything if the session is invalid
  }

  return children;
}

export default PrivateRoute;
