import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login'; 
import Main from './components/Main';
import PrivateRoute from './components/PrivateRoute'; // Import the PrivateRoute

function App() {
  return (
    <Router>
      <Routes>
        {/* Redirect root path to login page */}
        <Route path="/" element={<Navigate to="/login" />} />
        
        {/* Public login route */}
        <Route path="/login" element={<Login />} />
        
        {/* Private route for the main page */}
        <Route 
          path="/main" 
          element={
            <PrivateRoute>
              <Main />
            </PrivateRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;
