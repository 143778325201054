import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Check if the user is already logged in (has a valid token)
  useEffect(() => {
    const token = localStorage.getItem('token');
  
    // Verify token by making a request to the backend
    if (token) {
      axios.get('/main', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(() => {
        navigate('/main'); // If token is valid, navigate to the main page
      }).catch(() => {
        // Token is invalid, handle session timeout
        localStorage.removeItem('token'); // Remove invalid token
        alert('Session is timeout. Please log in again.');
      });
    }
  }, [navigate]);

  const handleLogin = () => {
    if (!email || !password) {
      setErrorMessage('Email and password are required!');
      return;
    }

    // Make a POST request to /login
    axios.post('/api/login', { email, password })
      .then(response => {
        // Save the token in localStorage
        localStorage.setItem('token', response.data.token);
        // Navigate to the main page after successful login
        navigate('/main');
      })
      .catch(error => {
        setErrorMessage('Invalid email or password');
      });
  };

  // Handle "Enter" key press to submit login form
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleLogin();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [email, password]);

  return (
    <div id="loginform">
      <h2 id="headerTitle">JDL Admin Page</h2>
      <div className="row">
        <label htmlFor="email">Email</label>
        <input 
          type="email" 
          placeholder="Enter your email" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="row">
        <label htmlFor="password">Password</label>
        <input 
          type="password" 
          placeholder="Enter your password" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}
      <div className="row" id="button">
        <button onClick={handleLogin}>Log In</button>
      </div>
    </div>
  );
}

export default Login;
