import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTable } from 'react-table';
import axios from 'axios';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { KeyboardSensor, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';

// Sortable row component using dnd-kit
const SortableRow = ({ row, moveRow, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: row.original.unique_id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </tr>
  );
};

// Dropdown cell for Key ID and Enemy Type
const EditableDropdownCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData, options }) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
    updateMyData(index, id, e.target.value);
  };

  return (
    <select value={value || ''} onChange={onChange} className="form-control">
      <option value="">Select...</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

// Text/number input cell for other fields
const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
  const [localValue, setLocalValue] = useState(initialValue);
  const inputRef = useRef(null);

  // Focus input if it was active before re-render
  useEffect(() => {
    setLocalValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    setLocalValue(e.target.value);
  };

  const onBlur = () => {
    const numericColumns = ['level', 'hp', 'moveSpeed', 'enemyCount', 'armor', 'gold'];
    let newValue = localValue;

    if (numericColumns.includes(id)) {
      newValue = parseFloat(localValue);
      if (!isNaN(newValue)) {
        newValue = parseFloat(newValue.toFixed(2));
      } else {
        newValue = 0;
      }
    }

    // Only update the global state onBlur
    updateMyData(index, id, newValue);
  };

  const isNumeric = ['level', 'hp', 'moveSpeed', 'enemyCount', 'armor', 'gold'].includes(id);

  return (
    <input
      ref={inputRef}
      value={localValue || ''}
      onChange={onChange}
      onBlur={onBlur}
      type={isNumeric ? 'number' : 'text'}
      step="0.01"
      className="form-control"
      // Add a stable key to ensure it doesn't lose focus
      key={`${index}-${id}`}
    />
  );
};

function EnemyData() {
  const [data, setData] = useState([]);
  const [keyIdOptions, setKeyIdOptions] = useState([]);
  const [enemyTypeOptions, setEnemyTypeOptions] = useState([]);
  const [isDataModified, setIsDataModified] = useState(false); // Track whether the data has been modified
  const tableRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get('/api/enemy-data', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const enemiesWithId = response.data.enemies.map((enemy, index) => ({
          ...enemy,
          unique_id: `${index}-${Date.now()}`, // Generate unique ID for each row
        }));

        setData(enemiesWithId);

        const uniqueKeyIds = [...new Set(enemiesWithId.map((enemy) => enemy.keyId))];
        const uniqueEnemyTypes = [...new Set(enemiesWithId.map((enemy) => enemy.enemyType))];

        setKeyIdOptions(uniqueKeyIds);
        setEnemyTypeOptions(uniqueEnemyTypes);
      })
      .catch((error) => console.error('Error fetching enemy data:', error));
  }, []);

  const saveData = () => {
    const token = localStorage.getItem('token');
    axios
      .post('/api/save-enemy-data', { enemies: data }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data && response.data.enemies) {
          const updatedEnemies = response.data.enemies.map((enemy) => {
            const numericColumns = ['level', 'hp', 'moveSpeed', 'enemyCount', 'armor', 'gold'];

            numericColumns.forEach((col) => {
              if (!isNaN(enemy[col])) {
                enemy[col] = parseFloat(parseFloat(enemy[col]).toFixed(2));
              }
            });

            return enemy;
          });

          setData(updatedEnemies);
        }
        alert('Enemy data saved successfully!');
        setIsDataModified(false); // Reset modification state after saving
      })
      .catch((error) => console.error('Error saving enemy data:', error));
  };

  const moveRow = useCallback((activeIndex, overIndex) => {
    if (activeIndex !== overIndex) {
      setData((old) => arrayMove(old, activeIndex, overIndex));
      setIsDataModified(true); // Mark the data as modified after DnD
    }
  }, []);

  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
    setIsDataModified(true); // Mark data as modified on cell update
  };

  // Add a new row at the end
  const addRow = () => {
    const newRow = {
      keyId: '',
      enemyType: '',
      level: 0,
      hp: 0,
      moveSpeed: 0,
      enemyCount: 0,
      armor: 0,
      gold: 0,
      unique_id: `${data.length}-${Date.now()}`, // Ensure a unique ID
    };
    setData((old) => [...old, newRow]);
    setIsDataModified(true);
    scrollToBottom(); // Scroll to the last added row
  };

  // Remove a row at a specific index
  const removeRow = (index) => {
    setData((old) => old.filter((_, i) => i !== index));
    setIsDataModified(true);
  };

  // Scroll to the bottom to focus on the last row
  const scrollToBottom = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8, // Drag will activate only if the mouse moves 8 pixels
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200, // 200ms delay before the drag is activated
        tolerance: 6, // Allow small movements before activation
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'drag',
        disableSortBy: true,
        Cell: () => <span className="drag-handle">&#9776;</span>,
      },
      {
        Header: 'Key ID',
        accessor: 'keyId',
        Cell: (props) => <EditableDropdownCell {...props} options={keyIdOptions} />,
      },
      {
        Header: 'Enemy Type',
        accessor: 'enemyType',
        Cell: (props) => <EditableDropdownCell {...props} options={enemyTypeOptions} />,
      },
      { Header: 'Level', accessor: 'level', Cell: EditableCell, type: 'number' },
      { Header: 'HP', accessor: 'hp', Cell: EditableCell, type: 'number' },
      { Header: 'Move Speed', accessor: 'moveSpeed', Cell: EditableCell, type: 'number' },
      { Header: 'Enemy Count', accessor: 'enemyCount', Cell: EditableCell, type: 'number' },
      { Header: 'Armor', accessor: 'armor', Cell: EditableCell, type: 'number' },
      { Header: 'Gold', accessor: 'gold', Cell: EditableCell, type: 'number' },
      {
        Header: 'Delete',
        id: 'actions',
        Cell: ({ row }) => (
          <span 
            className="remove-icon" 
            onClick={() => removeRow(row.original.unique_id)}
            style={{ cursor: 'pointer', color: '#dc3545' }} // Red color for delete action
          >
            <i className="fas fa-times"></i> {/* Font Awesome X icon */}
          </span>
        ),
      }
    ],
    [keyIdOptions, enemyTypeOptions]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    updateMyData,
  });

  return (
    <div className="container">
      <h1 className="text-center">Enemy Data</h1>

      <button className="save-button" onClick={saveData} disabled={!isDataModified}>
        Save Enemy Data
      </button>

      <button className="save-button" onClick={addRow} style={{ marginLeft: '20px' }}>
        Add Row
      </button>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={({ active, over }) => {
          if (active && over) {
            const activeIndex = rows.findIndex((row) => row.original.unique_id === active.id);
            const overIndex = rows.findIndex((row) => row.original.unique_id === over.id);
            moveRow(activeIndex, overIndex);
          }
        }}
        modifiers={[restrictToVerticalAxis]}
      >
        <div className="table-container">
          <table {...getTableProps()} className="custom-table" ref={tableRef}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <SortableContext items={rows.map((row) => row.original.unique_id)}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <SortableRow key={row.original.unique_id} row={row} moveRow={moveRow}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </SortableRow>
                  );
                })}
              </SortableContext>
            </tbody>
          </table>
        </div>
      </DndContext>
    </div>
  );
}

export default EnemyData;
